import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { growthBookFeatureFlags } from 'utils/featureFlags';
import { selectSignalDataList } from 'containers/IRRedirect/selectors';
import { setSelfNotificationDataAction } from 'containers/IRRedirect/actions';
import VideoServiceInterface from 'containers/IRRedirect/videoServiceInterface';
import { applyEffectOnInitialization } from 'containers/InterviewRooms/utils';
import {
  IR_MESSAGE_TYPES,
  IR_SIGNAL_SOURCE,
  IR_SIGNAL_TYPES,
  NOTIFICATION_TYPE,
  SIGNAL_MESSAGE,
} from 'containers/InterviewRooms/signalConstants';

export default function useLocalVideoToggle({ room, localTracks, getLocalVideoTrack, removeLocalVideoTrack, onError }) {
  const { background_images } = useFeatureValue(growthBookFeatureFlags.INTERVIEW_ROOMS_VIDEO_BACKGROUND_EFFECT) || {};
  const { irVideoServiceProvider } = VideoServiceInterface();
  const signalDataList = useSelector(selectSignalDataList);
  const localParticipant = room?.localParticipant;
  const isScreenTrack = track => track?.name?.includes('screen') || track?.source === 'screen_share';
  const videoTrack = localTracks?.find(track => track?.kind === 'video' && !isScreenTrack(track)) ?? null;

  const [isPublishing, setIsPublishing] = useState(false);
  const dispatch = useDispatch();
  const signalRef = useRef(signalDataList);

  useEffect(() => {
    signalRef.current = signalDataList;
  }, [signalDataList]);

  const onEnableVideoError = err => {
    const sendNotification = signal_message => {
      dispatch(
        setSelfNotificationDataAction({
          signal_type: IR_SIGNAL_TYPES.NOTIFICATION,
          signal_message,
          message_type: IR_MESSAGE_TYPES.DEFAULT,
          notification_type: NOTIFICATION_TYPE.WARNING,
          source: IR_SIGNAL_SOURCE.SELF,
          id: Math.random(),
        }),
      );
    };
    if (err && err.toString().includes('Permission')) sendNotification(SIGNAL_MESSAGE.ENABLE_CAMERA_PERMISSIONS);
    else if (!err && err.toString().includes('Device in use')) sendNotification(SIGNAL_MESSAGE.VIDEO_DEVICE_IN_USE);
    else sendNotification(SIGNAL_MESSAGE.VIDEO_DEVICE_HARDWARE_ISSUE);
    if (onError) onError(err);
  };

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIsPublishing(true);
        getLocalVideoTrack()
          ?.then(async track => {
            await applyEffectOnInitialization(track, irVideoServiceProvider, background_images);
            await localParticipant?.publishTrack(track, { priority: 'high' });
          })
          .catch(onEnableVideoError)
          .finally(() => setIsPublishing(false));
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);

  return [!!videoTrack, toggleVideoEnabled];
}
