import {
  IR_MESSAGE_TYPES,
  IR_SIGNAL_SOURCE,
  IR_SIGNAL_TYPES,
  NOTIFICATION_TYPE,
  SIGNAL_MESSAGE,
} from 'containers/InterviewRooms/signalConstants';

export const ERROR_NAME = { ABORT_ERROR: 'AbortError', NOT_ALLOWED_ERROR: 'NotAllowedError' };
export const ERROR_MESSAGE = {
  LIVE_KIT_ERROR_MESSAGE: 'Access to the feature "display-capture" is disallowed by permission policy',
  TWILIO_ERROR_MESSAGE: 'The request is not allowed by the user agent or the platform in the current context',
};

export const SCREEN_SHARE_FAILED_SIGNAL = {
  signal_type: IR_SIGNAL_TYPES.NOTIFICATION,
  signal_message: SIGNAL_MESSAGE.SCREEN_SHARE_FAILED,
  message_type: IR_MESSAGE_TYPES.DEFAULT,
  notification_type: NOTIFICATION_TYPE.WARNING,
  source: IR_SIGNAL_SOURCE.SELF,
  id: Math.random(),
};

export const SCREEN_SHARE_SELF_SIGNAL = {
  signal_type: IR_SIGNAL_TYPES.NOTIFICATION,
  signal_message: SIGNAL_MESSAGE.SHARING_YOUR_SCREEN,
  notification_type: NOTIFICATION_TYPE.INFO,
  source: IR_SIGNAL_SOURCE.SELF,
  id: Math.random(),
};

export const providersPriority = ['LIVEKIT', 'TWILIO', 'LIVEKIT_SELF_HOSTED'];

export const providerOptions = {
  LIVEKIT: 'LIVEKIT',
  TWILIO: 'TWILIO',
  LIVEKIT_SELF_HOSTED: 'LIVEKIT_SELF_HOSTED',
};
