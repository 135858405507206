/* eslint-disable no-unused-expressions */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setSelfNotificationDataAction } from 'containers/IRRedirect/actions';
import VideoServiceInterface from 'containers/IRRedirect/videoServiceInterface';
import { providerOptions } from 'contexts/VideoProvider/constants';
import {
  IR_MESSAGE_TYPES,
  IR_SIGNAL_SOURCE,
  IR_SIGNAL_TYPES,
  NOTIFICATION_TYPE,
  SIGNAL_MESSAGE,
} from 'containers/InterviewRooms/signalConstants';
import useIsTrackEnabled from './useIsTrackEnabled';

export default function useLocalAudioToggle({ localAudioTrack }) {
  const { irVideoServiceProvider } = VideoServiceInterface();
  const isTwilio = irVideoServiceProvider === providerOptions.TWILIO;
  const audioTrack = localAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);
  const dispatch = useDispatch();

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      if (!isTwilio) {
        audioTrack?.isMuted ? audioTrack?.unmute() : audioTrack?.mute();
      } else {
        audioTrack?.isEnabled ? audioTrack?.disable() : audioTrack?.enable();
      }
    } else {
      dispatch(
        setSelfNotificationDataAction({
          signal_type: IR_SIGNAL_TYPES.NOTIFICATION,
          signal_message: SIGNAL_MESSAGE.ENABLE_MIC_PERMISSIONS,
          message_type: IR_MESSAGE_TYPES.DEFAULT,
          notification_type: NOTIFICATION_TYPE.WARNING,
          source: IR_SIGNAL_SOURCE.SELF,
          id: Math.random(),
        }),
      );
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled];
}
